import React, { useEffect, useRef } from "react";
import { useVerification } from "../context/VerificationContext";
import styles from "../styles/faq.module.css";
import Loader from "./Loader";
const PoweredByDiscoveristLogo =
  " https://cdn.discoverist.ai/images/Discoverist-logo.webp";

export const InfoSectionChat = (props) => {
  const {
    messages,
    question,
    isGeneratingChat,
    isChatReady,
    sendChatReq,
    clearChatHistory,
    setContext,
    OGquestion,
    setOGquestion,
    removeContext,
    isContextSet,
    prodContext,
    handleDockButtonClose,
    add2cart,
    toggleChatBox,
    userProfile,
    setUserProfile,
    serverError,
    isUserChatSent,
    setIsUserChatSent,
  } = props;

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current?.scrollTop) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  };

  //   const { startUpQuestion, pdpQuestion } = botConfig;

  const defaultQuestion = [
    "What is your privacy policy?",
    "Is my credit card information kept secure?",
    "I don't wish to give my credit card information online. Are there other ways to order?",
    "Where are your items manufactured?",
  ];

  //   const startUpQuestionArray =
  //     startUpQuestion?.length > 0 ? startUpQuestion : defaultQuestion;

  useEffect(() => {
    scrollToBottom();
  }, [messages, isGeneratingChat, toggleChatBox]);
  return (
    <div
      className={styles.faqContainer}
      style={{
        display: toggleChatBox,
      }}
    >
      <div className={styles.faqHeader}>
        <div onClick={handleDockButtonClose}>
          <i className="fa-solid fa-chevron-down"></i>
        </div>
        <div onClick={clearChatHistory}>
          <i className="fa-solid fa-rotate-right"></i>
        </div>
      </div>
      <div className={styles.faqBanner}>
        <h1>
          WHAT CAN WE HELP <br></br> YOU FIND
        </h1>
        <div className={styles.faqSearchContainer}>
          <button
            disabled={isGeneratingChat}
            type="submit"
            className={styles.faqSearchButton}
            onClick={() => sendChatReq(OGquestion)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
          <input
            type="text"
            className={styles.faqSearchInput}
            placeholder="Tell me what you're looking for?"
            disabled={!isChatReady}
            value={OGquestion}
            onChange={(e) => setOGquestion(e.target.value)}
            autoCapitalize="sentences"
            autoComplete="off"
            autoFocus={true}
            spellCheck={true}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendChatReq(OGquestion);
              }
            }}
            style={{ border: "none" }}
          />
        </div>
      </div>

      <div
        className={
          isChatReady
            ? styles.faqQuestionContainerGrid
            : styles.faqQuestionContainerFlex
        }
        hidden={isGeneratingChat || messages.length > 0}
        ref={chatContainerRef}
      >
        {isChatReady ? (
          defaultQuestion.map((el) => (
            <button
              disabled={!isChatReady}
              className={styles.faqQuestionBox}
              onClick={() => sendChatReq(`${el} `)}
            >
              {el}
            </button>
          ))
        ) : (
          <Loader type="connecting" />
        )}
      </div>

      {isGeneratingChat && (
        <div className={styles.faqQuestionSegment}>
          <div className={styles.faqQuestion}>{question}</div>
        </div>
      )}

      {isGeneratingChat && (
        <div
          className={styles.faqLoadingSegment}
          style={{ margin: "0px", paddingBottom: "0px" }}
        >
          <div
            className={styles.faqLoading}
            style={{ width: "90%", padding: "0px" }}
          >
            <p className="from-them loading">
              <span></span>
              <span></span>
              <span></span>
            </p>
          </div>
        </div>
      )}

      <div className={styles.faqMessageSegment}>
        {messages
          .slice()
          .reverse()
          .map((el, index) => (
            <div key={index}>
              <div className={styles.faqQuestionSegment}>
                <div className={styles.faqQuestion}>{el.question}</div>
              </div>

              <div className={styles.faqAnswerSegment}>
                <div className={styles.faqAnswer}>{el.answer}</div>
              </div>
            </div>
          ))}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5em",
          paddingBottom: "10px",
          backgroundColor: "#fff",
          fontSize: "11px",
          color: "#999",
        }}
      >
        <div style={{ paddingTop: "8px" }}>Powered by</div>
        <a
          href="https://discoverist.ai?ref=shopping-assistant"
          target="_blank"
          rel="noreferrer"
          aria-label="powered by discvoerist"
        >
          <img src={PoweredByDiscoveristLogo} height={12} />
        </a>
      </div>
    </div>
  );
};
