import React, { useEffect, useState } from "react";
import styles from "../styles/faqSearch.module.css";
import axios from "axios";
import { useVerification } from "../context/VerificationContext";
export const InfoSectionSearch = (props) => {
  const { productContext, assistantMode } = props;
  const { clientId } = useVerification();
  const getProdData = JSON.parse(localStorage.getItem("reviewsProduct"));
  const [showReviewSearch, setShowReviewSearch] = useState(false);
  const [aiSummary, setAiSummary] = useState("");
  const [text, setText] = useState("");
  const [reply, setReply] = useState({ question: "", answer: "" });
  const [isLoading, setIsLoading] = useState(false);
  const handleIframeEvent = (event) => {
    const isReviewSearchEnabled = event.data?.isReviewSearchEnabled;
    if (isReviewSearchEnabled !== undefined) {
      setShowReviewSearch(
        assistantMode === "reviews_search" && JSON.parse(isReviewSearchEnabled)
      );
    }
  };

  const sendMessage = async (text) => {
    setIsLoading(true);
    const payload = {
      ...(assistantMode === "reviews_search" && {
        product: {
          product_id: productContext?.id,
          product_price: productContext?.price,
        },
      }),
      question: text,
    };
    const endPoint =
      assistantMode === "reviews_search" ? "reviews_search" : "faq_search";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_RECOMMENDAION_API}/v1/clients/${clientId}/${endPoint}`,
        payload,
        {
          headers: {
            accept: "application/json",
            "x-api-key": process.env.REACT_APP_DISCOVERIST_API_KEY,
          },
        }
      );

      const result = response.data?.result;
      if (result) {
        setReply({ question: text, answer: result });
        setIsLoading(false);
        setText("")
      } else {
        setReply({ question: text, answer: "Something Went Wrong!" });
        setIsLoading(false);
        setText("")
      }
    } catch (error) {
      // Handle the error here
      console.error("Error:", error);
      setReply({ question: text, answer: "Something Went Wrong!" });
      setIsLoading(false);
      setText("")
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeEvent);
  }, [showReviewSearch]);

  useEffect(() => {
    if (productContext?.id || getProdData?.id) {
      (async function () {
        try {
          const url = `${
            process.env.REACT_APP_RECOMMENDAION_API
          }/v1/clients/${clientId}/review_summary/${
            productContext?.id ?? getProdData?.id
          }`;
          const settings = await axios
            .get(url, {
              headers: {
                "x-api-key": process.env.REACT_APP_DISCOVERIST_API_KEY,
              },
            })
            .then((response) => {
              setAiSummary(response.data.results);
            })
            .catch((error) => {
              console.error("Error:", error);
            });

          return true;
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, []);
  return (
    <div className={styles.firstContainer}>
      {
        <div
          className={styles.faqSearch}
          style={{
            display:
              showReviewSearch || assistantMode === "faq_search"
                ? "block"
                : "none",
          }}
        >
          <div className={styles.faqSearchContainer}>
            <button
              // disabled={isGeneratingChat}
              type="submit"
              className={styles.faqSearchButton}
              onClick={() => sendMessage(text)}
            >
              {isLoading ? (
                <i className="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <i className="fa-solid fa-magnifying-glass"></i>
              )}
            </button>
            <input
              type="text"
              className={styles.faqSearchInput}
              placeholder={
                assistantMode == "reviews_search"
                  ? "Search Cutomer Reviews"
                  : "Ask me a question?"
              }
              // disabled={!isChatReady}
              value={text}
              onChange={(e) => setText(e.target.value)}
              autoCapitalize="sentences"
              autoComplete="off"
              autoFocus={true}
              spellCheck={true}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage(text);
                }
              }}
              style={{ border: "none" }}
            />
          </div>
        </div>
      }

      <div className={styles.secondContainer}>
        {(reply.answer || reply.question) && (
          <div className={styles.faqQuestionAnswer}>
            <div className={styles.faqQuestion}>
              <span>{reply.question}</span>
            </div>
            <div className={styles.faqAnswer}>
              <div>
                <p> AI Answer:</p>
                <span> {reply.answer}</span>
              </div>
            </div>
          </div>
        )}
        {assistantMode == "reviews_search" && aiSummary && (
          <div className={styles.reviewSummaryContainer}>
            {" "}
            <h4 className={styles.reviewSummaryHeading}>
              AI Review Summary
            </h4>{" "}
            <div className={styles.reviewSummaryText}>{aiSummary}</div>{" "}
          </div>
        )}
      </div>
    </div>
  );
};
