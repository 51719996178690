import React from "react";
import styles from "../styles/Loader.module.css"; 
const Loader = (props) => {
  const { type } = props
  return type == "connecting" ?(
    <>
      <h1 className={styles.loaderText}>
        <span className={styles.let1} id="loaderAlphabet">C</span>
        <span className={styles.let2} id="loaderAlphabet">O</span>
        <span className={styles.let3} id="loaderAlphabet">N</span>
        <span className={styles.let4} id="loaderAlphabet">N</span>
        <span className={styles.let5} id="loaderAlphabet">E</span>
        <span className={styles.let6} id="loaderAlphabet">C</span>
        <span className={styles.let7} id="loaderAlphabet">T</span>
        <span className={styles.let8} id="loaderAlphabet">I</span>
        <span className={styles.let9} id="loaderAlphabet">N</span>
        <span className={styles.let10} id="loaderAlphabet">G</span>
        <span className={styles.let11} id="loaderAlphabet">.</span>
        <span className={styles.let12} id="loaderAlphabet">.</span>
        <span className={styles.let13} id="loaderAlphabet">.</span>
      </h1>
    </>
  ) : (<>
  <h1 className={styles.loaderText}>
        <span className={styles.let1} id="loaderAlphabet">L</span>
        <span className={styles.let2} id="loaderAlphabet">O</span>
        <span className={styles.let3} id="loaderAlphabet">A</span>
        <span className={styles.let4} id="loaderAlphabet">D</span>
        <span className={styles.let5} id="loaderAlphabet">I</span>
        <span className={styles.let6} id="loaderAlphabet">N</span>
        <span className={styles.let7} id="loaderAlphabet">G</span>
        <span className={styles.let8} id="loaderAlphabet">.</span>
        <span className={styles.let9} id="loaderAlphabet">.</span>
        <span className={styles.let10} id="loaderAlphabet">.</span>
      </h1>
  </>);
};

export default Loader;
