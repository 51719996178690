import { React, useEffect, useState } from "react";
import Slider from "react-slick";
import dummyImage from "../assets/images/dummy.jpg";
import {
  EVENT_SOURCE,
  MODULE_CLICKED_NEXT,
  MODULE_CLICKED_PREV,
  MODULE_LOADED_DISCOVERIST,
  PRODUCT_ADDED_TO_CART_CLICKED,
  PRODUCT_CLICKED,
  REDIRECT,
} from "../constants";
import { useVerification } from "../context/VerificationContext";
import { CartIcon } from "./icons/CartIcon";
import VariantModal from "./VariantModal";

let origin = window.self === window.top ? document.URL : document.referrer;
origin = origin.substring(0, origin.length - 1);

function SampleNextArrow(props) {
  const { onClick, currentSlide, slideCount, sendCustomEvent, isTestEvent } =
    props;
  return (
    <div
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 3 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === slideCount - 3 ? true : false}
      style={{
        width: "48px",
        height: "65px",
        borderRadius: "4px",
        boxSizing: "border-box",
        cursor: "pointer",
        display: currentSlide === slideCount - 3 ? "none" : "flex",
        color: "black",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "35%",
        right: "10px",
      }}
      onClick={() => {
        onClick();
        sendCustomEvent(MODULE_CLICKED_NEXT, {
          event_source: EVENT_SOURCE,

          is_testevent: isTestEvent,
        });
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="currentColor"
        className="bi bi-chevron-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick, currentSlide, sendCustomEvent, isTestEvent } = props;
  return (
    <div
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === 0 ? true : false}
      style={{
        width: "48px",
        height: "65px",
        borderRadius: "4px",
        boxSizing: "border-box",
        cursor: "pointer",
        zIndex: "2",
        color: "black",
        display: currentSlide === 0 ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "35%",
        left: "10px",
      }}
      onClick={() => {
        onClick();
        sendCustomEvent(MODULE_CLICKED_PREV, {
          event_source: EVENT_SOURCE,
          is_testevent: isTestEvent,
        });
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="currentColor"
        className="bi bi-chevron-left"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
      </svg>
    </div>
  );
}

function ImageList(props) {
  const {
    message,
    isGeneratingChat,
    sendChatReq,
    setContext,
    prodContext,
    isContextSet,
    add2cart,
    removeContext,
    canCallModuleLoadEvent,
    isUserChatSent,
    setIsUserChatSent,
  } = props;

  const { settings, sendCustomEvent, isTestEvent } = useVerification();
  const addToCartColor =
    settings.bot_styles.product_config.add_to_cart_bg_color;
  const cartColor = addToCartColor;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productVariant, setProductVariant] = useState({});

  useEffect(() => {
    if (canCallModuleLoadEvent && isUserChatSent) {
      const data = message.products?.map((item) => ({
        product_id: item.id,
        product_name: item.text,
        product_price: item.price,
      }));

      sendCustomEvent(MODULE_LOADED_DISCOVERIST, {
        event_source: EVENT_SOURCE,
        products: [...data],
        is_testevent: isTestEvent,
      });
      setIsUserChatSent(false);
    }
  }, [canCallModuleLoadEvent]);

  const slideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(message.products?.length, 3),
    slidesToScroll: 3,
    initialSlide: 3,
    nextArrow: (
      <SampleNextArrow
        sendCustomEvent={sendCustomEvent}
        isTestEvent={isTestEvent}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        sendCustomEvent={sendCustomEvent}
        isTestEvent={isTestEvent}
      />
    ),
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: Math.min(message.products?.length, 2),
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: false,
          nextArrow: (
            <SampleNextArrow
              sendCustomEvent={sendCustomEvent}
              isTestEvent={isTestEvent}
            />
          ),
          prevArrow: (
            <SamplePrevArrow
              sendCustomEvent={sendCustomEvent}
              isTestEvent={isTestEvent}
            />
          ),
        },
      },
    ],
  };

  return message.products?.length == 0 ? (
    ""
  ) : (
    <div>
      <div style={{ maxWidth: "750px", margin: "auto" }}>
        <Slider {...slideSettings}>
          {message.products?.map((product, index) => (
            <div
              id="carousel-container"
              key={index}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                padding: 20,
                paddingBottom: 0,
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 0,
                minWidth: "33%",
                borderRadius: 20,
                height: "fit-content",
                width: "auto",
                maxWidth: "203px",
              }}
            >
              <div
                style={{
                  border: "0px solid rgb(244, 244, 247)",
                  height: "fit-content",
                  position: "relative",
                  margin: "7px",
                  maxWidth: "203px",
                }}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={product.url}
                    alt=""
                    onError={(e) => {
                      e.target.src = dummyImage;
                    }}
                    style={{
                      height: "100%",
                      maxHeight: "193px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      sendCustomEvent(PRODUCT_CLICKED, {
                        event_source: EVENT_SOURCE,
                        products: [
                          {
                            product_id: product.id,
                            product_name: product.text,
                            product_price: product.price,
                          },
                        ],
                        is_testevent: isTestEvent,
                      });
                      if (window.parent?.postMessage) {
                        window.parent.postMessage(
                          {
                            id: REDIRECT.id,
                            type: REDIRECT.type,
                            discoveristData: {
                              product: product,
                              hrefData: {},
                              review: {},
                              search: {},
                            },
                          },
                          "*"
                        );
                      }
                      setContext(product);
                    }}
                  />
                </div>

                <div
                  className="product_info"
                  style={{
                    float: "left",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h6
                    className="sa_product_name"
                    style={{ float: "left", height: "40px", fontSize: "14px" }}
                  >
                    {product.text}
                  </h6>

                  <div
                    className="sa_product_prices"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        color: "rgb(43, 43, 43)",
                        fontSize: "var(--grouped-icon-font-size)",
                        fontWeight: "600",
                        alignItems: "left",
                        fontStyle: "italic",
                      }}
                    >
                      ${product.price}
                    </div>

                    <div>
                      <button
                        onClick={() => {
                          add2cart(product.text);
                          sendCustomEvent(PRODUCT_ADDED_TO_CART_CLICKED, {
                            event_source: EVENT_SOURCE,
                            products: [
                              {
                                product_id: product.id,
                                product_name: product.text,
                                product_price: product.price,
                              },
                            ],
                            is_testevent: isTestEvent,
                          });
                          setProductVariant(product);
                          setIsModalOpen(true);
                        }}
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: cartColor ? cartColor : "#3c5a96",
                          padding: "5px 7px",
                          borderRadius: "5px",
                          color: "white",
                          fontSize: "1rem",
                        }}
                      >
                        <CartIcon />
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ height: "150px" }}></div>
              </div>
            </div>
          ))}
        </Slider>
        {isModalOpen && (
          <VariantModal
            onClose={() => setIsModalOpen(false)}
            options={productVariant.options}
            product={productVariant}
            variants={productVariant.variants}
          />
        )}
      </div>
    </div>
  );
}

export default ImageList;
